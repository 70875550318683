<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-5">
                    <img src="../assets/images/logo.png" alt="Logo" class="footer-logo" />
                </div>
                <div class="col-7">
                    <div class="footer-links" style="display: flex; margin-top: 15px">
                        <router-link to="/privacy-policy" class="nav-link" href="#">Privacy Policy </router-link>
                        <router-link to="/terms-conditions" class="nav-link" href="#">Term & Condition </router-link>
                        <router-link to="/faq" class="nav-link" href="#">FAQs</router-link>
                        <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Invite a
                            Friend</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="footer__copyright text-center py-md-4 py-sm-3 py-2">
        <p class="mb-0" style="color: gray">Copyright © 2024 All rights reserved</p>
    </div>

    <!-- Invite Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content text-white">
                <button type="button" class="modal-cross-btn ms-auto position-relative bgc-secondary text-white" data-bs-dismiss="modal"
                    aria-label="Close">
                    <i class="fas fa-close"></i>
                </button>

                <div class="px-sm-4 px-3 pb-sm-5 pb-4">
                    <h5 class="color-primary">Invite a Friend</h5>
                    <p class="color-primary">Share</p>
                    <div class="d-flex justify-content-start flex-wrap">
                        <div class="d-flex flex-column justify-content-center align-items-center me-3">
                            <img src="@/assets/images/whatsapp.png" style="width: 45px; height: 45px" />
                            <span style="font-size: 12px" class="mt-2 color-secondary">WhatsApp</span>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center me-3">
                            <img src="@/assets/images/facebook.png" style="width: 45px; height: 45px" />
                            <span style="font-size: 12px" class="mt-2 color-secondary">Facebook</span>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center me-3">
                            <img src="@/assets/images/twitter.png" style="width: 45px; height: 45px" />
                            <span style="font-size: 12px" class="mt-2 color-secondary">X</span>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center me-3">
                            <img src="@/assets/images/linkedin.png" style="width: 45px; height: 45px" />
                            <span style="font-size: 12px" class="mt-2 color-secondary">LinkedIn</span>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center pt-sm-0 pt-2 me-3">
                            <img src="@/assets/images/email.png" style="width: 45px; height: 45px" />
                            <span style="font-size: 12px" class="mt-2 color-secondary">Email</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.modal-cross-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50px;
    top: -15px;
    right: -20px;
}
</style>