<template>
  <nav class="navbar navbar-expand-lg navbar-dark" :class="{ 'scroll-bg': bg }">
    <div class="container">
      <a class="navbar-brand fs-4" href="/">
        <img
          src="../assets/images/logo.png"
          style="width: 230px !important"
          class="navbar-logo"
        />
      </a>
      <button
        class="navbar-toggler shadow-none border-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="sidebar offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header text-white border-bottom">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            Employment x
          </h5>
          <button
            type="button"
            class="btn-close btn-close-white shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0">
          <div
            class="menu-container text-center"
            :class="{ 'border-none': bg, 'bg-transparent': bg }"
          >
            <router-link
              to="/"
              href="#"
              class="menu-item mx-2"
              @click="setActive(0)"
              :class="{ active: activeIndex === 0 }"
              >Home</router-link
            >
            <a
              href="#about-us"
              class="menu-item mx-2"
              @click="setActive(1)"
              :class="{ active: activeIndex === 1 }"
              >About us</a
            >
            <a
              href="#how-it-works"
              class="menu-item mx-2"
              @click="setActive(2)"
              :class="{ active: activeIndex === 2 }"
              >How It Works</a
            >
            <a
              href="#testimonials"
              class="menu-item mx-2"
              @click="setActive(3)"
              :class="{ active: activeIndex === 3 }"
              >Testimonial</a
            >
          </div>
          <div class="d-flex justify-content-end gap-4">
            <a
              href="https://portal.durshala.com"
              target="_blank"
              style="
                border-radius: 50px;
                height: 69px;
                width: 160px;
                line-height: 60px;
                font-weight: 600;
              "
              class="btn btn-primary btn-sm border-0 bgc-secondary"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      bg: false,
      activeIndex: null,
    };
  },
  mounted() {
    // Add scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 125) {
        this.bg = true;
      } else {
        this.bg = false;
      }
    },
    setActive(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style>
nav {
  position: sticky !important;
  top: 0;
  width: 100%;
  height: 125px;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}

.scroll-bg {
  background-color: rgba(255, 255, 255) !important;
}

.border-none {
  border: none !important;
}

.bg-transparent {
  background-color: transparent;
}
</style>
